import {parseStrEmpty} from "@/utils/index";

function get(key) {
  if(typeof localStorage !== "undefined") {
    return parseStrEmpty(localStorage.getItem(key))
  }
}

function set(key, value) {
  if (value === undefined) {
    return remove(key)
  } else {
    if(typeof localStorage !== "undefined") {
      return localStorage.setItem(key, parseStrEmpty(value))
    }
  }
}

function remove(key) {
  if(typeof localStorage !== "undefined") {
    return localStorage.removeItem(key)
  }
}

const cache = {
  get: get,
  set: set,
  remove: remove
}

export default cache
