import { guidSimple } from '@/utils'
import cache from '@/utils/cache'
const TokenKey = 'Admin-Token'
const DeviceIdKey = 'Device-Id'

export function getToken() {
  return cache.get(TokenKey)
}

export function setToken(token) {
  return cache.set(TokenKey, token)
}

export function removeToken() {
  return cache.remove(TokenKey)
}

export function getDeviceId() {
  let s = cache.get(DeviceIdKey);
  if(!s){
    s = guidSimple()
    setDeviceId(s)
  }
  return s;
}

export function setDeviceId(deviceId) {
  return cache.set(DeviceIdKey, deviceId)
}

export function removeDeviceId() {
  return cache.remove(DeviceIdKey)
}
