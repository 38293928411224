import request, {accountRequest} from '@/utils/axios'
import {accountWebServer} from "@/utils/constant";

// 获取用户详细信息
export function getInfo() {
  return request.get('/api/getUserInfo')
}

// 退出方法
export function logout() {
  accountRequest.get("/chinaMerger/website/loginAction!logOut.action")
  return accountRequest.get(accountWebServer +'/account/register!loginOut.action')
  /*return request({
    url: 'account/register!loginOut.action',
    method: 'post'
  })*/
  // return Promise.resolve()
}
export function loginByCode(data) {
  return request({
    url: "/api/loginByCode",
    method: 'post',
    timeout: 15000,
    data: data
  }).then(res => {
    return res
  },function (err){
    return err
  })
}

export function refreshSession(){
  return accountRequest.get(accountWebServer + "/website/register!checkIsLogged.action").then((res)=>{
    if(res && res.data ) {
      return res.data
    }
  })
}


