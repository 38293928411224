import {getInfo, loginByCode, logout} from '@/api/login'
import {getToken, removeToken, setToken} from '@/utils/auth'
import Vue from "vue";
import {cookieKey} from "@/utils/constant";

const state = () => ({
  token: getToken(),
  user: null,
})

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
    if (Vue.prototype.$cookies) {
      Vue.prototype.$cookies.set(cookieKey, token)
    }
    setToken(token)
  },
  SET_USER: (state, user) => {
    state.user = user
  },
}

const actions = {
  // 登录
  LoginByCode({commit, dispatch}, code) {
    return new Promise((resolve, reject) => {
      const req = loginByCode(code);
      req.then(res => {
        if (res && res.data) {
          commit('SET_TOKEN', res.data)
          dispatch('user/GetInfo', null, {root: true})
          resolve(res)
        } else {
          reject(res)
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 获取用户信息
  GetInfo({commit}) {
    return new Promise((resolve, reject) => {
      getInfo().then(res => {
        const user = res.data
        if (!user) {
          reject()
        } else {
          if(user.isremove === '1'){
            reject({data:{resultMsg: '您当前登录的账号已被注销'},remove:'1'})
          } else {
            commit('SET_USER', user)
            resolve(user)
          }
        }
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 退出系统
  LogOut({commit}) {
    return new Promise((resolve, reject) => {
      logout().then(() => {
        commit('SET_TOKEN', '')
        commit('SET_USER', null)
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

