import Vue from 'vue'
import Vuex from 'vuex'
import userState from './user';


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    ready: false,
    menuItems: [],
    piwikUrl: '',
  },
  getters: {
    user: state => state.user.user,
    token: state => state.user.token,
    ready: state => state.ready,
    menuItems: state => state.menuItems,
    piwikUrl: state => state.piwikUrl,
  },
  mutations: {
    SET_READY: (state, ready) => {
      state.ready = ready
    },
    SET_MENU_ITEMS: (state, menuItems) => {
      state.menuItems = menuItems
    },
    SET_PIWIK_URL: (state, piwikUrl) => {
      state.piwikUrl = piwikUrl
    },
  },
  actions: {
    setReady({commit}, ready) {
      return new Promise((resolve) => {
        commit('SET_READY', ready !== undefined ? ready : true)
        resolve()
      })
    },
    setMenuItems({commit}, menuItems) {
      return new Promise((resolve) => {
        commit('SET_MENU_ITEMS', menuItems)
        resolve()
      })
    },
  },
  modules: {
    user: userState,
  }
})
