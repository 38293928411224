import {isArray, isString} from "@/utils/validate";
import Vue from 'vue'
import {deepClone, isUndefined} from "@/utils/index";

const messageDefault = {
  position: 'top-left',
  title: '系统提示'
}
const confirmActions = [{
  text: '取消', color: 'grey', key: 'cancel'
}, {
  text: '确认', color: 'blue', key: 'ok'
}]
const alertActions = [{
  text: '关闭', color: 'black', key: true
}]

const toastOpt = {color: 'grey',}

function messageOption(options, funOptions) {
  if (isString(options)) {
    options = {
      text: options
    };
  }
  if (!options) {
    options = {
      text: ''
    }
  }
  if (funOptions) {
    return Object.assign({}, messageDefault, funOptions, options);
  }
  return Object.assign({}, messageDefault, options);
}

function getDialog() {
  return Vue.prototype.$dialog || {
    info() {}, confirm() {}, prompt() {}, error() {}, warning() {},
    message: {info() {}, confirm() {}, prompt() {}, error() {}, warning() {},},
    notify: {info() {}, confirm() {}, prompt() {}, error() {}, warning() {},}
  }
}

function changeRes(actions, res) {
  if (actions && res) {
    if (isArray(actions) && actions.length) {
      for (let i = 0; i < actions.length; i++) {
        const action = actions[i];
        if ((!isUndefined(action.key)) && action.text === res) {
          return action.key
        }
      }
    }
  }
  return res
}

/**
 * 参考 https://madewith.cn/4
 * option
 * {
 *   position: 'top-right',
 *   title: '系统提示'
 *   actions:['确认','取消']
 * }
 * actions: {
 *     'false': 'No',
 *     'true': 'Yes'
 *     }
 * }
 * actions: [{
 *     text: 'Yes', color: 'blue', key: true
 * }]
 * actions: ['No', 'Yes']
 * @type
 */
const Message = {
  info(options) {
    options = messageOption(options, {color: 'light-blue darken-2'})
    if (!options.actions) {
      options.actions = alertActions
      return new Promise(((resolve, reject) => {
        getDialog().info(options).then(res => {
          if (res === alertActions[0].key) {
            resolve(res)
          } else {
            reject('close')
          }
        })
      }))
    }
    const actions = deepClone(options.actions || {})
    return new Promise(resolve => {
      const info =getDialog().info(options);
      if(info) {
        info.then(res => {
          resolve(changeRes(actions, res))
        })
      } else {
        resolve("")
      }
    })
  },
  alert(options) {
    return this.info(options)
  },
  confirm(options) {
    options = messageOption(options, {color: 'light-blue darken-2'})
    if (!options.actions) {
      options.actions = confirmActions
      return new Promise(((resolve, reject) => {
        getDialog().confirm(options).then(res => {
          if (res === confirmActions[1].key) {
            resolve(res)
          } else {
            reject(res || 'close')
          }
        })
      }))
    }
    const actions = deepClone(options.actions || {})
    return getDialog().confirm(options).then(res => {
      return changeRes(actions, res)
    })
  },
  prompt(options) {
    options = messageOption(options)
    if (!options.actions) {
      options.actions = confirmActions
      return new Promise(((resolve, reject) => {
        getDialog().prompt(options).then(res => {
          if (res === confirmActions[1].key) {
            resolve(res)
          } else {
            reject(res || 'close')
          }
        })
      }))
    }
    const actions = deepClone(options.actions || {})
    return getDialog().prompt(options).then(res => {
      return changeRes(actions, res)
    })
  },
  error(options) {
    options = messageOption(options)
    if (!options.actions) {
      options.actions = alertActions
      return new Promise(((resolve, reject) => {
        getDialog().error(options).then(res => {
          if (res === alertActions[0].key) {
            resolve(res)
          } else {
            reject('close')
          }
        })
      }))
    }
    const actions = deepClone(options.actions || {})
    return getDialog().error(options).then(res => {
      return changeRes(actions, res)
    })
  },
  warning(options) {
    options = messageOption(options)
    if (!options.actions) {
      options.actions = alertActions
      return new Promise(((resolve, reject) => {
        getDialog().warning(options).then(res => {
          if (res === alertActions[0].key) {
            resolve(res)
          } else {
            reject('close')
          }
        })
      }))
    }
    const actions = deepClone(options.actions || {})
    return getDialog().warning(options).then(res => {
      return changeRes(actions, res)
    })
  },
  message: {
    info(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().message.info(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    success(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().message.success(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    error(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().message.error(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    warning(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().message.warning(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    toast(options) {
      options = messageOption(options, toastOpt)
      if (typeof window !== 'undefined' && window.plugins && window.plugins.toast) {
        const duration = 'short';
        const position = "bottom";
        return new Promise((resolve) => {
          window.plugins.toast.show(options.text, duration, position)
          resolve()
        })
      } else {
        const actions = deepClone(options.actions || {})
        return getDialog().message.warning(options.text, options).then(res => {
          return changeRes(actions, res)
        })
      }
    }
  },
  notify: {
    info(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().notify.info(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    success(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().notify.success(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    error(options) {
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().notify.error(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    },
    warning(options) {
      // options = messageOption(options,{timeout:50000})
      options = messageOption(options)
      const actions = deepClone(options.actions || {})
      return getDialog().notify.warning(options.text, options).then(res => {
        return changeRes(actions, res)
      })
    }
  },
}
export default Message
