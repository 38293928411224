import axios from "axios";
import Message from "@/utils/message";
import Vue from "vue";
import qs from "qs";
import {getDeviceId, getToken} from "@/utils/auth";
import {addParam} from "@/utils/index";

//axios.defaults.baseURL = "http://localhost:8081";
const request = axios.create({
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});
const requestFilter = config => {

  const withOutToken = (config.headers || {}).withOutToken === false
  const token = getToken()
  if (token && !withOutToken) {
    config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  config.headers['deviceId'] = getDeviceId();
  config.headers['Content-type']= 'application/x-www-form-urlencoded';

  if (config.data && config.data.showMessageType) {
    config.headers['showMessageType'] = config.data.showMessageType
  }
  config.data = qs.stringify(config.data, {allowDots: true})
  config.url = addParam(config.url, "_", new Date().getTime() + parseInt(Math.random() * 1000 + ""))
  return config
}
request.interceptors.request.use(requestFilter);
request.interceptors.response.use(
  response => {
    let res = response.data;
    if (res.data && res.state === 200) {
      return res.data
    } else if(!res.headers || res.headers.showMessageType !== 'none') {
      if(res.data && res.data.errorCode !== '305202') {
        if(res.data.errorMsg) {
          Message.error(res.data.errorMsg);
        } else {
          Message.error(!(res.data && res.data.resultMsg) ? '系统异常' : res.data.resultMsg);
        }
      }
      return Promise.reject(response.data.msg)
    }
  },
  error => {
    if (error.response.data) {
      error.message = error.response.data.msg
    }
    if (error.response.status === 401) {
      Vue.prototype.$changePage("/login")
    } else {
      Message.error(error.message, {duration: 3 * 1000});
    }
    return Promise.reject(error)
  }
);
export default request

export const fileRequest = axios.create({
  timeout: 5000,
  responseType: 'blob',
  headers: {
    'Content-Type': 'application/json; charset=utf-8'
  }
});
fileRequest.interceptors.request.use(requestFilter);

export const accountRequest = axios.create({
  timeout: 30000,
  withCredentials: true
})
