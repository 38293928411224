import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import {changePage, consoleLog, createPiwikUrl, goToBackUrl, mTimeout, openUrl} from "@/utils";
import VuetifyDialog from 'vuetify-dialog'
import 'vuetify-dialog/dist/vuetify-dialog.css'
import '@/assets/base.scss'
import VueCookies from "vue-cookies";

Vue.use(VueCookies)
Vue.use(VuetifyDialog, {
  context: {
    vuetify
  }
})
Vue.config.productionTip = false

Vue.prototype.$changePage = (url, noHistory, backUrl) => {
  return changePage(router, url, noHistory, backUrl)
}
Vue.prototype.$goToBackUrl = (noHistory, defaultUrl) => {
  return goToBackUrl(router, noHistory, defaultUrl)
}
Vue.prototype.$consoleLog = (...log) => {
  return consoleLog(...log)
}
let goBacking = false
Vue.prototype.$goBack = () => {
  return new Promise(resolve => {
    if (goBacking) {
      return
    }
    goBacking = true
    goToBackUrl(router).then(() => {
      goBacking = false
      resolve()
    })
  })
}
Vue.prototype.$timeout = (fn, timeout) => {
  return mTimeout(fn, timeout)
}
Vue.prototype.$openUrl = (url, type) => {
  openUrl(store, url, type)
}
Vue.prototype.$createPiwikUrl = (title, subTitle, notDoCreate) => {
  return createPiwikUrl(title, subTitle, store, notDoCreate)
}

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
